import React, { useState } from "react"
import { Link } from "gatsby"
import { Div, Text, Row, Col, Container, Image, Tag, Button } from "atomize"

import intro1 from "../../images/icons/intro1.svg"
import intro2 from "../../images/icons/intro2.svg"
import intro3 from "../../images/icons/intro3.svg"
import intro4 from "../../images/icons/intro4.svg"
import { ModalSize } from "../formModal"
import firebase from "gatsby-plugin-firebase"
import SampleCall from "../sampleCall"

const list = [
  {
    icon: intro1,
    heading: "uTele - Gọi Tự Động",
    subheading: "Tự động gọi điện 1000 khách hàng cùng lúc",
    link: "utele",
  },
  {
    icon: intro2,
    heading: "uCenter - Tổng Đài Thông Minh",
    subheading:
      "Xây dựng tổng đài gọi tự động bằng trí tuệ nhân tạo chỉ với 3 bước đơn giản",
    link: "ucenter",
  },

  {
    icon: intro3,
    heading: "uLeads - Tìm kiếm khách hàng",
    subheading:
      "Tìm kiếm data khách hàng, số điện thoại khách hàng tiềm năng từ mạng xã hội miễn phí",
    link: "uleads",
  },

  {
    icon: intro4,
    heading: "uAnalytics - Phân tích khách hàng",
    subheading:
      "Phân tích và khai phá insight khách hàng giúp doanh nghiệp thấu hiểu khách hàng",
    link: "uanalytics",
  },
]

const Intoducting = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <Div tag="section" id="features" p={{ t: "2rem" }}>
      <ModalSize isOpen={showModal} onClose={() => setShowModal(false)} />

      <Container>
        <Row>
          <Tag
            bg="black"
            textColor="white"
            h="2rem"
            p={{ x: "1rem" }}
            rounded="circle"
            m={{ b: "2rem" }}
          >
            Giải pháp
          </Tag>
          <Text
            textSize="display1"
            textWeight="700"
            fontFamily="secondary"
            color="info700"
            m={{ b: "1rem" }}
          >
            Giải pháp callbot giúp doanh nghiệp của bạn tiếp cận hàng trăm nghìn
            khách hàng hoàn toàn tự động
          </Text>
          <Text
            textSize="subheader"
            textColor="medium"
            maxW="30rem"
            m={{ b: "3rem" }}
          >
            uCall sử dụng công nghệ trí tuệ nhân tạo kết hợp với thu thập dữ
            liệu lớn giúp doanh nghiệp tìm kiếm, tiếp cận và thấu hiểu khách
            hàng. Từ đó, doanh nghiệp tiết kiệm được thời gian và chi phí vận
            hành, đồng thời gia tăng doanh thu từ việc bán hàng tự động.
          </Text>
        </Row>
        <Div
          p={{ b: "6rem" }}
          border={{ b: "1px solid" }}
          borderColor="gray300"
        >
          <Row>
            {list.map((item) => (
              <Col size={{ xs: 12, sm: 6, lg: 3 }}>
                <Div m={{ b: { xs: "1rem", lg: "0" } }}>
                  <Div
                    border="1px solid"
                    borderColor="gray200"
                    h="100%"
                    d="flex"
                    flexDir="column"
                    p="2rem"
                    shadow="3"
                    rounded="xl"
                    minH="450px"
                  >
                    <Div flexGrow="1">
                      <Image
                        src={item.icon}
                        m={{ t: "1rem", b: "2rem" }}
                        w="auto"
                        h="2rem"
                      />
                      <Text textSize="title" textWeight="500" m={{ b: "1rem" }}>
                        {item.heading}
                      </Text>
                      <Text
                        textSize="subheader"
                        textColor="medium"
                        m={{ b: "2rem" }}
                      >
                        {item.subheading}
                      </Text>
                    </Div>
                    <Button
                      bg="transparent"
                      onClick={() => {
                        firebase
                          .analytics()
                          .logEvent(`tap_on_tim_hieu_${item.link}`)
                        setShowModal(true)
                      }}
                    >
                      <Text
                        textColor="info700"
                        hoverTextColor="info800"
                        textWeight="500"
                      >
                        Tìm Hiểu
                      </Text>
                    </Button>
                  </Div>
                </Div>
              </Col>
            ))}
          </Row>
        </Div>
      </Container>
    </Div>
  )
}

export default Intoducting

import React, { useState } from "react"
import {
  Div,
  Text,
  Container,
  Anchor,
  Button,
  Icon,
  Row,
  Col,
  Input,
} from "atomize"
import { PrimaryColor } from "../colors"
import useCountDown from "react-countdown-hook"
import "../index.css"
import moment from "moment"
import firebase from "gatsby-plugin-firebase"
import DHBD from "../../images/daihocbinhduong.jpg"
import TuyetMy from "../../images/logo-kien-truc-tuyet-my-02.png"
import Beerlao from "../../images/beerlao.png"
import CleverGroup from "../../images/clever_group.png"
import MBBank from "../../images/Logo_MB_new.png"
import SafetyChoice from "../../images/LOGO-SAFETYCHOICE.VN-FINAL-web-01-e1627433617850.png"
import ShbFinance from "../../images/shbfinance.png"
import Moma from "../../images/LogoMoMa (1).jpg"
import Tiva from "../../images/tiva.png"
import CMetric from "../../images/cmetric.png"
import RichLand from "../../images/richland.png"
import CCV from "../../images/ccv.png"
import Xland from "../../images/xland.jpg"
import Rectangle1 from "../../images/Rectangle 59.png"
import Rectangle2 from "../../images/Rectangle 61.png"
import Rectangle3 from "../../images/Rectangle 63.png"
import Rectangle4 from "../../images/Rectangle 67.png"
import Rectangle5 from "../../images/Rectangle 68.png"
import axios from "axios"
import LeadsForm from "../LeadsForm"

const list = [
  {
    image: Rectangle1,
    title: "Công nghệ",
    content:
      "Sử dụng trí tuệ nhân tạo để tạo ra cuộc gọi chân thật, dễ nghe. Tương tác tự nhiên với người dùng.",
  },
  {
    image: Rectangle2,
    title: "Kịch bản linh hoạt",
    content: "Dễ dàng tạo kịch bản cho ngành hàng khác nhau",
  },
  {
    image: Rectangle3,
    title: "Phản hồi tức thì",
    content: "Sẵn sàng phản hồi 24/7",
  },
  {
    image: Rectangle4,
    title: "Tối ưu chi phí và thời gian",
    content:
      "Nhanh chóng gọi đến hàng nghìn khách hàng của bạn, không cần đến nhân viên",
  },
  {
    image: Rectangle5,
    title: "Tích hợp đơn giản",
    content: "Tích hợp dễ dàng với các nền tảng chuyển đổi số",
  },
]
const initialTime = 60 * 1000
const interval = 1000
const GettingStartedBanner = () => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const [timeLeft, { start, pause, resume, reset }] = useCountDown(
    initialTime,
    interval
  )

  // start the timer during the first render
  React.useEffect(() => {
    start()
  }, [])

  return (
    <Div m={{ t: "3rem" }}>
      <Container>
        <Text
          tag="h1"
          textWeight="700"
          textAlign="center"
          textSize="display3"
          fontFamily="secondary"
          textColor={PrimaryColor}
          m={{ b: "1rem" }}
        >
          Tại sao bạn nên sử dụng uCall?
        </Text>
        <Div
          border={{ t: "0px solid" }}
          borderColor="gray300"
          p={{ y: "4rem" }}
        >
          <Row style={{ justifyContent: "center" }}>
            {list.map((item, index) => (
              <Col
                key={index}
                size={{ xs: 12, sm: 12, lg: 4 }}
                d="flex"
                align="center"
                flexDir="column"
              >
                <Div
                  d="flex"
                  maxW="20rem"
                  minH="26rem"
                  flexDir="column"
                  align="center"
                  m={{ b: "3rem" }}
                  bg="rgba(44,57,160,0.1)"
                  style={{
                    borderRadius: "24px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  p={"2rem"}
                >
                  <img
                    src={item.image}
                    m={{ b: "3rem" }}
                    style={{ width: "4rem" }}
                  />
                  <Text
                    textSize="display1"
                    textWeight="700"
                    textAlign="center"
                    fontFamily="secondary"
                    m={{ t: "1rem", b: "1rem" }}
                  >
                    {item.title}
                  </Text>
                  <Text
                    textAlign="center"
                    textSize="subheader"
                    textColor="medium"
                  >
                    {item.content}
                  </Text>
                </Div>
              </Col>
            ))}
          </Row>
        </Div>
      </Container>
      <Div bg={{ xs: "gray200", md: "white" }}>
        <Container>
          <Div
            p={{ y: "3.5rem", x: { xs: "0", md: "4rem" } }}
            bg="gray200"
            style={{ borderRadius: "56px" }}
            d="flex"
            flexDir={{ xs: "column", lg: "row" }}
            justify="space-between"
          >
            <Div>
              <Text textSize="heading" textWeight="600" m={{ b: "0.5rem" }}>
                Ưu đãi đặc biệt: Dùng thử miễn phí 🚀
              </Text>
              <Text
                textSize="subheader"
                textColor="medium"
                m={{ b: { xs: "3rem", lg: "0" } }}
              >
                Gọi tới 100 khách hàng của bạn!
              </Text>
            </Div>
            <Countdown
              timeTillDate="09 09 2021, 11:59 pm"
              timeFormat="MM DD YYYY, h:mm a"
            />
            <Div d="flex" align="flex-end">
              <Anchor href="https://growth.ucall.vn/" target="_blank">
                <Button
                  rounded="circle"
                  shadow="1"
                  hoverShadow="4"
                  w="15rem"
                  h="3rem"
                  p={{ x: "1.5rem" }}
                  justify="space-between"
                  suffix={<Icon name="LongRight" size="20px" color="white" />}
                  onClick={() => {
                    firebase.analytics().logEvent("timer_call_to_action")
                  }}
                  bg={PrimaryColor}
                >
                  Bắt đầu dùng thử ngay!
                </Button>
              </Anchor>
            </Div>
          </Div>
        </Container>
      </Div>
      <Div m={{ b: "3rem", t: "4rem" }}>
        <Container>
          <Text
            tag="h1"
            textWeight="700"
            textAlign="center"
            textSize="2rem"
            fontFamily="secondary"
            textColor={"black"}
            m={{ b: "1rem" }}
          >
            Doanh nghiệp tiêu biểu đã tin tưởng chúng tôi
          </Text>
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://ccvgroupvn.com" target="_blank">
                <img
                  src={CCV}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="http://support.xlandglobal.com" target="_blank">
                <img
                  src={Xland}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://richlandthanhhoa.com" target="_blank">
                <img
                  src={RichLand}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }} style={{ padding: "24px" }}>
              <a href="https://cmetric.vn" target="_blank">
                <img
                  src={CMetric}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://clevergroup.vn" target="_blank">
                <img
                  src={CleverGroup}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://www.shbfinance.com.vn" target="_blank">
                <img
                  src={ShbFinance}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://www.mbbank.com.vn" target="_blank">
                <img
                  src={MBBank}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://www.bdu.edu.vn" target="_blank">
                <img
                  src={DHBD}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://moma.vn" target="_blank">
                <img
                  src={Moma}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://tiva.vn" target="_blank">
                <img
                  src={Tiva}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://tuyetmy.com.vn" target="_blank">
                <img
                  src={TuyetMy}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <img
                src={Beerlao}
                height={200}
                alt={"df"}
                className={"contain"}
                style={{ maxWidth: "200px" }}
              />
            </Col>
            <Col size={{ xs: 6, md: 4, lg: 3 }}>
              <a href="https://safetychoice.vn" target="_blank">
                <img
                  src={SafetyChoice}
                  height={200}
                  alt={"df"}
                  className={"contain"}
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </Div>
      <Container>
        <Div
          m={{ t: "4rem" }}
          p={{ y: "3rem", x: { xs: "1rem", md: "4rem" } }}
          bg={PrimaryColor}
          w={"100%"}
          style={{ borderRadius: "12px" }}
        >
          <Row>
            <Col size={{ xs: 12, md: 6, lg: 6 }}>
              <Text
                textColor={"white"}
                textSize={{ xs: "1rem", md: "2rem", lg: "3rem" }}
                style={{
                  fontWeight: 700,
                }}
              >
                Bắt đầu trải nghiệm ngay với 100 cuộc gọi miễn phí!
              </Text>
            </Col>
            <Col size={{ xs: 12, md: 6, lg: 6 }}>
              <Text
                textColor={"white"}
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  marginBottom: "12px",
                }}
              >
                Hãy để lại thông tin và chúng tôi sẽ liên hệ với bạn
              </Text>
              <LeadsForm />
            </Col>
          </Row>
        </Div>
      </Container>
    </Div>
  )
}

class Countdown extends React.Component {
  state = {
    days: undefined,
    hours: undefined,
    minutes: undefined,
    seconds: undefined,
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      const { timeTillDate, timeFormat } = this.props
      const then = moment(timeTillDate, timeFormat)
      const now = moment()
      const countdown = moment(then - now)
      const days = countdown.format("D")
      const hours = countdown.format("HH")
      const minutes = countdown.format("mm")
      const seconds = countdown.format("ss")

      this.setState({ days, hours, minutes, seconds })
    }, 1000)
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  render() {
    const { days, hours, minutes, seconds } = this.state
    const daysRadius = mapNumber(days, 30, 0, 0, 360)
    const hoursRadius = mapNumber(hours, 24, 0, 0, 360)
    const minutesRadius = mapNumber(minutes, 60, 0, 0, 360)
    const secondsRadius = mapNumber(seconds, 60, 0, 0, 360)

    if (!seconds) {
      return null
    }

    return (
      <div>
        <div className="countdown-wrapper">
          {days && (
            <div className="countdown-item">
              <SVGCircle radius={daysRadius} />
              {days}
              <span>ngày</span>
            </div>
          )}
          {hours && (
            <div className="countdown-item">
              <SVGCircle radius={hoursRadius} />
              {hours}
              <span>giờ</span>
            </div>
          )}
          {minutes && (
            <div className="countdown-item">
              <SVGCircle radius={minutesRadius} />
              {minutes}
              <span>phút</span>
            </div>
          )}
          {seconds && (
            <div className="countdown-item">
              <SVGCircle radius={secondsRadius} />
              {seconds}
              <span>giây</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const SVGCircle = ({ radius }) => (
  <svg className="countdown-svg">
    <path
      fill="none"
      stroke="#333"
      stroke-width="4"
      d={describeArc(50, 50, 48, 0, radius)}
    />
  </svg>
)

// From stackoverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

function describeArc(x, y, radius, startAngle, endAngle) {
  var start = polarToCartesian(x, y, radius, endAngle)
  var end = polarToCartesian(x, y, radius, startAngle)

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ")

  return d
}

// Stackoverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
function mapNumber(number, in_min, in_max, out_min, out_max) {
  return ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
}
export default GettingStartedBanner

import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaV2,
} from "react-recaptcha-x"
import React, { useState } from "react"
import firebase from "gatsby-plugin-firebase"
import {
  Button,
  Div,
  Input,
  Text,
  Label,
  Radiobox,
  Row,
  Col,
  Container,
} from "atomize"
import axios from "axios"
import PopUpBanner from "./PopUpBanner"
import Cookies from "js-cookie"
import { format } from "date-fns"

const genders = ["anh", "chị"]

const campaigns = [
  {
    id: "d30be602-2c9d-484a-8160-067049b084e6",
    name: "Bất động sản (ghi âm)",
    type: "recording",
  },
  {
    id: "9cd58a1a-cecd-4b72-acb4-76074e98ba46",
    name: "Tài chính (ghi âm)",
    type: "recording",
  },
  {
    id: "f7d3e0f2-3be9-4469-8849-177fe03c7257",
    name: "Giáo dục (cá nhân hoá)",
    type: "personalize",
  },
  {
    id: "99d884f8-6028-4f51-b246-b149fa821fb4",
    name: "Thẩm mỹ viện (cá nhân hoá)",
    type: "personalize",
  },
]

const SampleCall = ({ setShow }) => {
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [recaptcha, setRecaptcha] = useState()
  const [phoneNumber, setPhoneNumber] = useState("")
  const [name, setName] = useState("")
  const [gender, setGender] = useState("bạn")
  const [selectCampaign, setSelectCampaign] = useState(campaigns[0]?.id)
  const campaign = campaigns.find((c) => c.id === selectCampaign)
  const v2Callback = (data) => {
    setRecaptcha(data)
  }
  console.log(selectCampaign, campaign)

  const data = {
    attributes: {
      name: name,
      gender: gender,
    },
    phone_number: phoneNumber,
    recaptcha: recaptcha,
  }

  const submit = async () => {
    setLoading(true)
    try {
      const date = new Date()
      await axios.post("https://admin.ucall.vn/api/lead", {
        phone_number: phoneNumber,
        name,
        source: "LANDINGPAGE_FORM",
        date: Cookies.get("date")
          ? Cookies.get("date")
          : format(date, "LLLdd")?.toUpperCase(),
        channel: Cookies.get("channel") ? Cookies.get("channel") : "NULL",
        marketer: Cookies.get("marketer") ? Cookies.get("marketer") : undefined,
        label: Cookies.get("label") ? Cookies.get("label") : "NULL",
        content: Cookies.get("content") ? Cookies.get("content") : "NULL",
        contact: Cookies.get("contact") ? Cookies.get("contact") : "NULL",
        call_to_action: "DEMO",
        // life_cycle: "LOST",
      })
      // const COMPANY_SLUG = "ucalladmin5"
      const COMPANY_SLUG = "7746e247-0a12-4e50-b137-837d02d01e4f"
      const CALL_CAMPAIGN_ID = selectCampaign
      await axios.post(
        `https://admin.ucall.vn/api/company/${COMPANY_SLUG}/call_campaign/${CALL_CAMPAIGN_ID}/customer_demo`,
        data
      )

      setMessage("success")
      setShow(true)
    } catch (e) {
      console.log(e)
      setMessage("error")
      setRecaptcha(undefined)
    }
    setLoading(false)
  }

  const isDisabled =
    !data.attributes.name ||
    !data.attributes.gender ||
    !data.phone_number ||
    !data.recaptcha
  return (
    <Container>
      <Div
        style={{
          borderRadius: "12px",
          border: "1px solid rgba(0,0,0,0.2)",
          padding: "24px 16px",
        }}
        m={{ b: "3rem" }}
      >
        <div>
          <Text
            tag="h1"
            textSize="display2"
            style={{ color: "rgb(30, 39, 109)" }}
            m={{ b: "1rem" }}
          >
            Trải Nghiệm Demo
          </Text>
          <Row>
            <Col size={{ sm: 12, md: 6 }}>
              <Input
                name="name"
                onChange={(e) => setName(e.currentTarget.value)}
                placeholder="Tên của bạn"
                m={{ b: "1rem" }}
              />
              <Input
                name="phone"
                onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                placeholder="Số điện thoại"
                m={{ b: "1rem" }}
              />

              <Div style={{ marginBottom: "20px" }}>
                <Text
                  tag="h1"
                  textSize="display6"
                  style={{ color: "rgb(30, 39, 109)" }}
                >
                  Kịch bản
                </Text>
                <Row
                  m={{ t: "1rem" }}
                  align="center"
                  style={{ display: "flex", flexDirection: "row" }}
                  textAlign="center"
                >
                  {campaigns.map((c) => (
                    <Label
                      align="center"
                      textWeight="600"
                      m={{ b: "0.5rem", r: "2rem" }}
                    >
                      <Radiobox
                        onChange={() => setSelectCampaign(c.id)}
                        checked={selectCampaign === c.id}
                        name="count"
                      />
                      {c.name}
                    </Label>
                  ))}
                </Row>
              </Div>
            </Col>
            <Col size={{ sm: 12, md: 6 }}>
              {campaign && campaign.type !== "recording" && (
                <Div style={{ marginBottom: "20px" }}>
                  <Text
                    tag="h1"
                    textSize="display6"
                    style={{ color: "rgb(30, 39, 109)" }}
                  >
                    Xưng danh
                  </Text>
                  <Row m={{ t: "1rem" }} align="center" textAlign="center">
                    {genders.map((g) => (
                      <Label
                        align="center"
                        textWeight="600"
                        m={{ b: "0.5rem", r: "2rem" }}
                      >
                        <Radiobox
                          onChange={() => setGender(g)}
                          checked={gender === g}
                          name="count"
                        />
                        {g}
                      </Label>
                    ))}
                  </Row>
                </Div>
              )}
              <ReCaptchaV2
                callback={v2Callback}
                theme={EReCaptchaV2Theme.Light}
                size={EReCaptchaV2Size.Normal}
                id="recaptcha"
                tabindex={0}
              />
              {message === "error" && (
                <Text
                  textColor="danger700"
                  textSize="subheader"
                  textWeight="500"
                >
                  Có lỗi xảy ra. Hãy thử lại sau.
                </Text>
              )}
              {message === "success" && (
                <Text
                  textColor="success700"
                  textSize="subheader"
                  textWeight="500"
                >
                  Chúng tôi đang gọi đến bạn...
                </Text>
              )}
              <Div d="flex" style={{ marginTop: "12px" }} justify="flex-end">
                <Button
                  disabled={isDisabled}
                  onClick={submit}
                  type="button"
                  isLoading={loading}
                  bg="info700"
                  w="100%"
                >
                  Gọi thử
                </Button>
              </Div>
            </Col>
          </Row>
        </div>
      </Div>
    </Container>
  )
}
export default SampleCall

import React from "react"
import {
  Div,
  Text,
  Row,
  Col,
  Container,
  Image,
  Icon,
  Button,
  Tag,
} from "atomize"

import react from "../../images/react.svg"

import icon1 from "../../images/feature-icons/1.svg"
import icon2 from "../../images/feature-icons/2.svg"
import icon3 from "../../images/feature-icons/3.svg"
import icon4 from "../../images/feature-icons/4.svg"
import icon5 from "../../images/feature-icons/5.svg"
import icon6 from "../../images/feature-icons/6.svg"

const featuresList = [
  {
    icon: icon2,
    heading: "Bước 1: Chọn kịch bản",
    subheading:
      "Kịch bản đánh giá sản phẩm, xác nhận đơn hàng, mời sự kiện, báo trúng thưởng,...Tất cả đều có sẵn",
  },

  {
    icon: icon5,
    heading: "Bước 2: Nhập danh sách khách hàng",
    subheading:
      "Nhập danh sách hàng từ file Excel, CRM, Google Sheet,...Số về đến đâu, uCall gọi đến đấy.",
  },
  {
    icon: icon4,
    heading: "Bước 3: Chạy chiến dịch",
    subheading: "1-click và...làm tách cafe",
  },
]

class Features extends React.Component {
  render() {
    return (
      <Div tag="section">
        <Container>

        </Container>
      </Div>
    )
  }
}

export default Features

import React, { useEffect } from "react"
import { useState } from "react"
import { Modal, Image, Input, Div, Button } from "atomize"
import PopupBannerImage from "../images/popupbanner.png"
import firebase from "gatsby-plugin-firebase"
import axios from "axios"
import LeadsForm from "./LeadsForm"
const PopUpBanner = ({ show, setShow }) => {
  const onClose = () => {
    setShow(false)
  }
  return (
    <div>
      <Modal onClose={onClose} isOpen={show} align="center" rounded="sm">
        <Image src={PopupBannerImage} style={{ borderRadius: "12px" }} />
        <LeadsForm />
      </Modal>
    </div>
  )
}

export default PopUpBanner

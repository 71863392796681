import React, { useEffect, useState } from "react"
import { Text, Div, Tag, Row, Col, Image, Link, Container } from "atomize"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/common/header"
import HeroSection from "../components/homepage/HeroSection"
import Introducing from "../components/homepage/Introducing"
import Features from "../components/homepage/Features"
import Craft from "../components/homepage/Craft"
import GetStartedBanner from "../components/homepage/GetStartedBanner"
import Footer from "../components/homepage/Footer"
import firebase from "gatsby-plugin-firebase"
import MessengerChat from "../components/messenger"
import { CardCategory, nganhs } from "../components/CardCategory"
import { ReCaptchaProvider } from "react-recaptcha-x"
import SampleCall from "../components/sampleCall"
import OverviewFeature from "../components/overviewFeature"
import PopUpBanner from "../components/PopUpBanner"
import { Helmet } from "react-helmet"

const IndexPage = () => {
  const [show, setShow] = useState(false)
  const layout = "layout-2"

  useEffect(() => {
    firebase.analytics().logEvent("visit_page")

    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params.vnp_ResponseCode) {
      window.location.replace(
        `https://growth.ucall.vn/?${new URLSearchParams(params).toString()}`
      )
    }
  }, [])

  useEffect(() => {
    firebase.analytics().setUserProperties({ layout: layout })
  }, [layout])

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 15000)
  }, [])
  return (
    <ReCaptchaProvider
      siteKeyV2="6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9"
      langCode="en"
      hideV3Badge={false}
    >
      <Layout>
        <Helmet>
          <meta
            property="og:title"
            content="uCall - Callbot gọi tự động Telemarketing và CSKH"
          />
          <meta
            property="og:description"
            content="Giải pháp giúp doanh nghiệp gọi hàng nghìn cuộc gọi tự động, thông minh với 1 cú click chuột"
          />
        </Helmet>
        <MessengerChat />
        {layout !== "" && (
          <React.Fragment>
            <Header layout={layout} />
            <HeroSection layout={layout} />
            <div style={{ height: "64px" }} />
            <SampleCall setShow={setShow} />
            <Div tag="caseStudy" id="caseStudy" m={{ t: "4rem" }}>
              <Container>
                <Tag
                  bg="black"
                  textColor="white"
                  h="2rem"
                  p={{ x: "1rem" }}
                  rounded="circle"
                  m={{ b: "2rem" }}
                >
                  Case Study
                </Tag>
                <Text
                  textSize="display1"
                  textWeight="700"
                  fontFamily="secondary"
                  color="info700"
                  m={{ b: "1rem" }}
                >
                  Giải pháp callbot dành riêng cho bạn
                </Text>
                <Div
                  p={{ b: "6rem" }}
                  border={{ b: "1px solid" }}
                  borderColor="gray300"
                >
                  <Row>
                    {nganhs.map((item) => (
                      <Col size={{ xs: 12, sm: 6, lg: 3 }}>
                        <Div m={{ b: { xs: "1rem", lg: "0" } }}>
                          <CardCategory nganh={item} />
                        </Div>
                      </Col>
                    ))}
                  </Row>
                </Div>
              </Container>
            </Div>
            <Introducing layout={layout} />
            <OverviewFeature />
            {/*<DesignDevelopment />*/}
            <GetStartedBanner layout={layout} />
            <PopUpBanner show={show} setShow={setShow} />
          </React.Fragment>
        )}
      </Layout>
    </ReCaptchaProvider>
  )
}

export default IndexPage
